<template>
	<div id="main" style="width:600px;height:400px" v-show="data.dataList.length > 0 && data.valueList.length > 0"></div>

	<el-empty description="暂无数据" v-show="data.dataList.length < 1 || data.valueList.length < 1" />
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, reactive } from 'vue';
import { getNetWorthData } from './chartApi.js';


const props = defineProps({
	fundId: {
		type: Number,
	}
})

let data = reactive({
	dataList: [],
	valueList: []
})
onMounted(() => {
	// console.log('data.dataList', data.dataList);
	// marketQuotations(data)
})
const marketQuotations = (fundId) => {
	var formData = new FormData();
	if (fundId) {
		formData.append("fundId", fundId);
	} else {
		formData.append("fundId", props.fundId);

	}

	getNetWorthData(formData).then((res) => {
		data.dataList = res.data.data.value;
		data.valueList = res.data.data.date;
		// 基于准备好的dom，初始化echarts实例  这个和上面的main对应
		let myChart = echarts.init(document.getElementById("main"));
		// 指定图表的配置项和数据
		let option = {
			title: {
				text: "净值图",
			},
			tooltip: {},
			legend: {
				data: ["净值"],
			},
			xAxis: {
				data: data.valueList,
			},
			yAxis: {},
			grid: {
				left: '18%', // 左侧数值被遮挡调整这个属性
			},
			series: [
				{
					name: "净值",
					type: "line",
					data: data.dataList,
				},
			],
		};
		myChart.setOption(option);
	})

}

defineExpose({
	marketQuotations
});
</script>

<style></style>